import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ContentModel } from '../models/content.model';
import { environment } from '../../environments/environment';
import { forkJoin, map, Observable, of } from 'rxjs';
import { GenericResponse } from '../models/generic-response.model';

@Injectable({
  providedIn: 'root',
})
export class MastersService {
  private readonly urls = {
    content: `${environment.digigoldbe}/content-master`,
    faq: `${environment.digigoldbe}/content-master/faq`,
  };

  faqs?: { question: string; answer: string; tags: string }[] | null;

  constructor(private readonly httpClient: HttpClient) {}

  getContent(name: string) {
    return this.httpClient
      .get<GenericResponse<{ value: string }>>(`${this.urls.content}/${name}`)
      .pipe(
        map((resp) => {
          return resp.data?.value;
        })
      );
  }

  getFaqs(
    name: string
  ): Observable<
    { question: string; answer: string; tags: string }[] | null | undefined
  > {
    if ((this.faqs?.length ?? 0) == 0) {
      return this.httpClient
        .get<
          GenericResponse<
            { question: string; answer: string; tags: string }[] | null
          >
        >(`${this.urls.faq}/${name}`)
        .pipe(
          map((resp) => {
            this.faqs = resp.data;
            return this.faqs;
          })
        );
    }
    return of(null);
  }

  getAllFaqs() {
    return forkJoin({
      faq: this.getAllForKey('faq'),
      faqSip: this.getAllForKey('faq-sip'),
      faqBuy: this.getAllForKey('faq-buy'),
      faqSell: this.getAllForKey('faq-sell'),
    });
  }

  getAllForKey(name: string) {
    return this.httpClient
      .get<
        GenericResponse<
          { question: string; answer: string; tags: string }[] | null
        >
      >(`${this.urls.faq}/${name}`)
      .pipe(
        map((resp) => {
          this.faqs = resp.data;
          return this.faqs;
        })
      );
  }
}
